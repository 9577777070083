<template>
    <div>
        <app-layout>
	        <template v-slot:header>
		        <Header :title="$t('prep_schedule_draft_title')"
		                :is-filter="false">
		        </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('prep_schedule_draft_title')"
		                      :is-filter="false">
		        </HeaderMobile>
	        </template>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-6 col-xs-12">
                            <div class="row">
                                <div class="col-xs-12 col-6">
                                    <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('academic_year')">
                                            <academic-years-selectbox
                                                :set-active="true"
                                                :validate-error="errors[0]"
                                                v-model="defineForm.academic_year">
                                            </academic-years-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xs-12 col-6">
                                    <ValidationProvider name="module_id" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('module')">
                                            <module-selectbox
                                                :academic_year="defineForm.academic_year"
                                                :validateError="errors[0]"
                                                v-model="defineForm.module_id">
                                            </module-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xs-12 col-6">
                                    <ValidationProvider name="campus" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('campus')">
                                            <campus-selectbox
                                                :validateError="errors[0]"
                                                v-model="defineForm.campusId">
                                            </campus-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xs-12 col-6">
                                    <ValidationProvider name="building" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('building')">
                                            <building-selectbox
                                                :campus_id="defineForm.campusId"
                                                :validateError="errors[0]"
                                                v-model="defineForm.buildingId">
                                            </building-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12">
                                    <b-form-group>
                                        <b-button variant="primary" @click="addClassroom">{{ $t('add') }}
                                        </b-button>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-xs-12">
                            <b-form-group :label="$t('classroom')">
                                <div class="checkbox-div">
                                    <b-form-group>
                                        <b-input v-model="filterInput" class="mb-2"></b-input>
                                        <div class="university-checkbox-list">
                                            <b-form-checkbox-group
                                                v-model="defineForm.classroomIds"
                                                :options="filteredData"
                                            >
                                            </b-form-checkbox-group>
                                        </div>
                                    </b-form-group>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="classrooms.length>0">
                            <table class="table table-bordered table-striped">
                                <tr>
                                    <th class="width-100">{{ $t('classroom') }}</th>
                                    <th class="width-100"></th>
                                </tr>
                                <tr v-for="classroom in classrooms" v-bind:key="classroom.id">
                                    <td>{{ classroom.name }}</td>
                                    <td>
                                        <b-button class="text-danger" variant="link" size="xs"
                                                  @click="deleteClassroom(classroom.id)"
                                        >
                                            {{ $t('delete').toUpper() }}
                                        </b-button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-5" v-if="classrooms.length>0">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{ $t('create') }}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
        </app-layout>
    </div>
</template>
<style type="scss">
.checkbox-div {
    border: 1px solid #e9ecef;
    border-radius: .375rem;
    height: 280px;
    padding: 10px;
    overflow-x: auto;
}
</style>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import {ValidationObserver, ValidationProvider} from "vee-validate";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import ClassroomService from "@/services/ClassroomService";
import PlacementExamClassroomsService from "@/services/PlacementExamClassroomsService";
import qs from "qs";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
import PrepScheduleDraftsService from "@/services/PrepScheduleDraftsService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CampusSelectbox,
        BuildingSelectbox,
        AppLayout,
        Header,
	    HeaderMobile,
        AcademicYearsSelectbox,
        ModuleSelectbox
    },

    props: {

    },

    data() {
        return {
            defineForm: {
                campusId: null,
                buildingId: null,
                classroom: null,
                classroomIds: undefined

            },

            classroomItems: [],
            classrooms: [],
            filterInput: '',
        }
    },
    metaInfo() {
        return {
            title: this.$t("course_schedule_from_draft")
        }
    },
    methods: {

        addClassroom() {
            if (this.defineForm.classroomIds.length === 0) {
                return false;
            }

            this.defineForm.classroomIds.forEach(id => {
                if (this.getOptionItem(this.classrooms, id)) {
                    return;
                }
                let item = this.getOptionItem(this.classroomItems, id);
                item.classroom_id = id;
                this.classrooms.push(item);
            })
        },
        deleteClassroom(id) {
            this.classrooms = this.classrooms.filter(function (item) {
                return item.id != id;
            });
        },
        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let classroomIds=[];
                this.classrooms.forEach(item=>{
                    classroomIds.push(item.id);
                });
                let formData = {
                    module_id: this.defineForm.module_id,
                    classroom_ids: classroomIds,
                    paramsSerializer: (classroom_ids) => qs.stringify(classroom_ids, {encode: false})
                }

                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('course_schedule_will_be_created_from_draft'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {

                    PrepScheduleDraftsService.create(formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('hide')
                        this.defineForm = {
                            campusId: null,
                            buildingId: null,
                            classroom: null,
                        }
                        this.classrooms=[];
                        this.classroomItems=[];
                        this.$refs.updateForm.reset();
                    }).catch(e => {
                        if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                            if (e.data.message) {
                                this.$refs.updateForm.errors.campus.push(this.$t('api.' + e.data.message));
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        }
                    })
                })
            }
        },
    },

    created() {

    },

    watch: {
        'defineForm.buildingId': {
            handler: function (val) {
                if (val == null) {
                    return;
                }

                let config = {
                    params: {
                        limit: -1,
                        filter: {
                            building_id: val
                        },
                        sort: 'name'
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                ClassroomService.getAll(config)
                    .then(response => {
                        this.classroomItems = [];
                        response.data.data.forEach(item => {
                            item.value = item.id;
                            item.text = item.name;
                            this.classroomItems.push(item);
                        })
                    });
            }
        }
    },
    computed: {
        filteredData() {
            return this.classroomItems.filter(item => {
                return item.name.toLowerCase().indexOf(this.filterInput.toLowerCase()) > -1
            })
        },
    }
};
</script>
<style>
.custom-checkbox{
    width: 10rem;
}
</style>
